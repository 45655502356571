import React from 'react'
import { Wrap, Title } from '../components/page/components'
import SEO from '../components/SEO'

const CareerModules = () => {
  return (
    <Wrap>
      <SEO title="Career Modules" />
      <Title>
        Career Modules
        <br />
        Coming Soon
      </Title>
    </Wrap>
  )
}

export default CareerModules
